exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/About.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-christianization-js": () => import("./../../../src/pages/Christianization.js" /* webpackChunkName: "component---src-pages-christianization-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/Cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-engagement-js": () => import("./../../../src/pages/Engagement.js" /* webpackChunkName: "component---src-pages-engagement-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/Home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-others-js": () => import("./../../../src/pages/Others.js" /* webpackChunkName: "component---src-pages-others-js" */),
  "component---src-pages-photoshoots-js": () => import("./../../../src/pages/Photoshoots.js" /* webpackChunkName: "component---src-pages-photoshoots-js" */),
  "component---src-pages-politics-js": () => import("./../../../src/pages/Politics.js" /* webpackChunkName: "component---src-pages-politics-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/Portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/Services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/Terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-wedding-js": () => import("./../../../src/pages/Wedding.js" /* webpackChunkName: "component---src-pages-wedding-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

